import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Lang, Tenant } from '../types/types'
import { LABELS } from '../labels/common'
import { MYRIOUNIS_COM } from '../labels/tenants/myriounis.com'
import { GLENS_GR } from '../labels/tenants/glens.gr'
import Link from '../components/Link'

const TENANT = process.env.REACT_APP_TENANT as Tenant

const isLangValid = (lang: Lang) => ['en', 'gr'].includes(lang)

const Impressum = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [lang, setLang] = useState(searchParams.get('lang') as Lang)

  useEffect(
    () => void (!isLangValid(lang) && setSearchParams({ lang: 'gr' })),
    []
  )
  useEffect(() => setLang(searchParams.get('lang') as Lang), [searchParams])

  const TENANT_LABELS = { MYRIOUNIS_COM, GLENS_GR }[TENANT]

  if (!TENANT_LABELS || !isLangValid(lang)) return <></>

  return (
    <div className='relative flex justify-center items-center bg-gray-200 p-4 h-screen'>
      <div className='bg-white text-center p-8 rounded-lg max-w-xl'>
        {
          <>
            <h1 className='text-2xl font-bold'>{TENANT_LABELS.h1[lang]}</h1>
            <h2 className='text-xl font-medium'>{TENANT_LABELS.h2[lang]}</h2>
            <br />
            <p>{LABELS.activites[lang]}:</p>
            <ul className='italic'>
              {Object.keys(TENANT_LABELS.activites)
                .sort()
                .map((activity: any, index) => (
                  <li key={(TENANT_LABELS.activites as any)[activity][lang]}>
                    {(TENANT_LABELS.activites as any)[activity][lang]}
                    {index < Object.keys(TENANT_LABELS.activites).length - 1 &&
                      ','}
                  </li>
                ))}
            </ul>
            <br />
            <p>
              {LABELS.administrator[lang]}: {TENANT_LABELS.administrator[lang]}
            </p>
            <p>
              {LABELS.vat[lang]}: {TENANT_LABELS.vat[lang]}
            </p>
            <p>
              {LABELS.gemi[lang]}:{' '}
              <Link
                url={`https://publicity.businessportal.gr/company/${TENANT_LABELS.gemi[lang]}`}
              >
                {TENANT_LABELS.gemi[lang]}
              </Link>
            </p>
            <p>
              {LABELS.address[lang]}:{' '}
              <Link url={TENANT_LABELS.address.link[lang]}>
                {TENANT_LABELS.address.part1[lang]},
                <br />
                {TENANT_LABELS.address.part2[lang]}
              </Link>
            </p>
            <p>
              {LABELS.website[lang]}:{' '}
              <Link url={`https://${TENANT_LABELS.website[lang]}`}>
                {TENANT_LABELS.website[lang]}
              </Link>
            </p>
            <br />
            <p>
              {LABELS.email[lang]}:{' '}
              <Link url={`mailto:${TENANT_LABELS.email[lang]}`}>
                {TENANT_LABELS.email[lang]}
              </Link>
            </p>
            <p>
              {LABELS.phone[lang]}:{' '}
              <Link
                url={`tel:${TENANT_LABELS.phone[lang].replace(
                  '(+30) ',
                  '+30'
                )}`}
              >
                {TENANT_LABELS.phone[lang]}
              </Link>
            </p>
          </>
        }
      </div>
      <div className='absolute top-0 right-0 mt-4 mr-4'>
        <select
          className='px-4 py-2 rounded-lg border'
          onChange={e => setSearchParams({ lang: e.target.value })}
        >
          <option selected={lang === 'gr'} value='gr'>
            Ελληνικά <span className='text-2l'>🇬🇷</span>
          </option>
          <option selected={lang === 'en'} value='en'>
            English <span className='text-2l'>🇬🇧</span>
          </option>
        </select>
      </div>
    </div>
  )
}

export default Impressum
