export const MYRIOUNIS_COM = {
  h1: {
    en: 'Myriounis Eleftherios',
    gr: 'Μυριούνης Ελευθέριος',
  },
  h2: {
    en: 'Single Member Private Company',
    gr: 'Μονοπρόσωπη Ιδιωτική Κεφαλαιουχική Εταιρεία',
  },
  activites: {
    1: {
      en: 'Information technology design and development services',
      gr: 'Υπηρεσίες σχεδιασμού και ανάπτυξης τεχνολογιών της πληροφορίας',
    },
    2: {
      en: 'Information technology systems programming activities',
      gr: 'Δραστηριότητες προγραμματισμού ηλεκτρονικών συστημάτων',
    },
    3: {
      en: 'Information technology systems and software consulting services',
      gr: 'Υπηρεσίες παροχής συμβουλών για θέματα συστημάτων και λογισμικού',
    }
  },
  administrator: {
    en: 'Eleftherios Myriounis',
    gr: 'Ελευθέριος Μυριούνης',
  },
  vat: {
    en: 'EL 802392050',
    gr: '802392050',
  },
  gemi: {
    en: '175540006000',
    gr: '175540006000',
  },
  address: {
    link: {
      en: 'https://maps.app.goo.gl/Kmm5XGg8mzaoiCdVA',
      gr: 'https://maps.app.goo.gl/Kmm5XGg8mzaoiCdVA',
    },
    part1: {
      en: 'Tsimiski Ioannou 75',
      gr: 'Τσιμισκή Ιωάννου 75',
    },
    part2: {
      en: '54622, Thessaloniki, Greece',
      gr: '54622, Θεσσαλονίκη',
    }
  },
  website: {
    en: 'myriounis.com',
    gr: 'myriounis.com',
  },
  email: {
    en: 'terry@myriounis.com',
    gr: 'terry@myriounis.com',
  },
  phone: {
    en: '(+30) 6989459502',
    gr: '6989459502',
  },
}
