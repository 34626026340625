import { Tenant } from '../types/types'
import Redirect from './Redirect'

const TENANT = process.env.REACT_APP_TENANT as Tenant
const isGlen = TENANT === 'GLENS_GR'

const Home = () => {
  if (isGlen)
    return (
      <Redirect
        url={'https://www.booking.com/hotel/gr/glen-premium-stays.el.html'}
        placeholder={'Booking.com'}
      />
    )

  return <Redirect url={'/impressum'} placeholder={'impressum'} />
}

export default Home
