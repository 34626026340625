import { useEffect } from 'react'

const cvUrl = '/cv/terry-myriounis.pdf'

/* This code is a React component that redirects the user to a link/url when the
component is rendered. */
const Redirect = (props: { url: string; placeholder: string }) => {
  useEffect(() => {
    window.location.href = props.url
  }, [])

  return (
    <div>
      Redirecting to <a href={cvUrl}>{props.placeholder}</a>
    </div>
  )
}

export default Redirect
