import { Labels } from '../types/types'

export const LABELS: Labels = {
  activites: {
    en: 'Activities',
    gr: 'Δραστηριότητες',
  },
  administrator: {
    en: 'Administrator',
    gr: 'Διαχειρηστής',
  },
  vat: {
    en: 'VAT number',
    gr: 'ΑΦΜ',
  },
  gemi: {
    en: 'GEMI number',
    gr: 'ΓΕΜΗ',
  },
  address: {
    en: 'Address',
    gr: 'Διεύθυνη',
  },
  website: {
    en: 'Website',
    gr: 'Ιστοσελίδα',
  },
  email: {
    en: 'Email',
    gr: 'Email',
  },
  phone: {
    en: 'Phone',
    gr: 'Τηλέφωνο',
  },
}
